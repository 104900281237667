<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive p-1">
                            <table
                                id="mail-templates-datatable"
                                class="table table-centered table-striped text-center"
                            >
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Mailing via Klaviyo</th>
                                        <th>Created at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="template in mailTemplates"
                                        :key="template.id"
                                        class="clickable-row"
                                        @click="
                                            $router.push(
                                                `/mailing/${template.id}`
                                            )
                                        "
                                    >
                                        <td>{{ template.code }}</td>
                                        <td>{{ template.name }}</td>
                                        <td>{{ template.description }}</td>
                                        <td v-if="template.klaviyo_enabled">
                                            <span class="badge bg-soft-success text-success">
                                                Enabled
                                            </span>
                                        </td>
                                        <td v-else>
                                            <span
                                                class="badge bg-soft-danger text-danger"
                                            >
                                                Disabled
                                            </span>
                                        </td>
                                        <td>
                                            {{ template.created_at | formatDate }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Mailing',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        return {
            title: 'Mailing',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Mailing',
                    active: true
                }
            ],
            mailTemplates: []
        };
    },

    async mounted() {
        try {
            const templates = await this.getMailTemplates();

            this.mailTemplates = templates;
        } catch (err) {
            console.error(err);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getMailTemplates: 'mailTemplates/index'
        })
    }
};
</script>
